import React from 'react';
import { useSelector } from 'react-redux';
import toLower from 'lodash/toLower';
import { getFundingSourceLabel } from 'src/utils/bills';
import { MIFormattedCurrency, MIFormattedDate, MIFormattedText } from 'src/utils/formatting';
import { getPayment, getSelectedFundingSource } from 'src/redux/payBillWizard/selectors';
import { chakra } from 'src/theme/ds';

export const ConfirmAgreements = () => {
  const payment = useSelector(getPayment);
  const selectedFundingSource = useSelector(getSelectedFundingSource);

  return (
    <Container>
      <MIFormattedText
        label="bills.pay.confirm.confirmTerms"
        values={{
          amount: <MIFormattedCurrency value={payment?.amount || 0} />,
          type: <MIFormattedText label={getFundingSourceLabel(selectedFundingSource)}>{toLower}</MIFormattedText>,
          date: <MIFormattedDate date={payment.scheduledDate} />,
        }}
      />
    </Container>
  );
};

const Container = chakra('div', {
  baseStyle: {
    textStyle: 'body10',
    textAlign: 'center',
    color: '#393a3d',
    margin: '20px 0',
  },
});
