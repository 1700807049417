import { DebugLevelEnum } from './consts';
import { Logger, LoggerMessage, LoggerSessionContext } from './types';

let sessionContext: LoggerSessionContext = {
  userId: '',
  orgId: '',
  email: '',
};

export const consoleLogger: Logger = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  init() {},
  getDebugLevel(): DebugLevelEnum {
    if (process.env.REACT_APP_ENV === 'production') {
      return DebugLevelEnum.INFO;
    }

    return DebugLevelEnum.DEBUG;
  },
  setSessionContext(value: LoggerSessionContext) {
    sessionContext = value;
  },
  debug(message?: LoggerMessage, ...optionalParams: any[]): void {
    // eslint-disable-next-line no-console
    console.debug(message, ...optionalParams, sessionContext);
  },
  log(message?: LoggerMessage, ...optionalParams: any[]): void {
    // eslint-disable-next-line no-console
    console.log(message, ...optionalParams, sessionContext);
  },
  info(message?: LoggerMessage, ...optionalParams: any[]): void {
    // eslint-disable-next-line no-console
    console.info(message, ...optionalParams, sessionContext);
  },
  warn(message?: LoggerMessage, ...optionalParams: any[]): void {
    console.warn(message, ...optionalParams, sessionContext);
  },
  error(message?: LoggerMessage, ...optionalParams: any[]): void {
    console.error(message, ...optionalParams, sessionContext);
  },
};
