import { QBDTAccountType } from 'src/billpay/qbdt/services/qbdt/types';
import { formatISO } from 'date-fns';
import { MELIO_STATUS_CODES } from 'src/billpay/qbdt/services/qbdt/constants';
import { logger } from 'src/services/loggers';

export function debug(...args) {
  const timestamp = Date.now();
  logger.debug(`qbdtutil.debug(): ${timestamp}`, ...args);
}

export function fundingSourceTypeToAccountType(fsType) {
  switch (fsType) {
    case 'credit':
      return QBDTAccountType.CreditCard;

    default:
      return QBDTAccountType.Bank;
  }
}

export function fundingSourceToAccountType(melioFundingSource) {
  if (melioFundingSource?.fundingType === 'card') {
    if (!melioFundingSource?.cardAccount?.cardType) {
      throw Error('Missing card account type in funding source');
    }

    return fundingSourceTypeToAccountType(melioFundingSource?.cardAccount?.cardType);
  }

  return fundingSourceTypeToAccountType(melioFundingSource?.fundingType);
}

export function formatQBDTDate(date) {
  return formatISO(date, { representation: 'date' });
}

export function formatQBDTDateTime(date) {
  return formatISO(date, { representation: 'complete' });
}

export function amountFormat(number) {
  return Number.parseFloat(number).toFixed(2);
}

export function isVendorConflictError(code) {
  return (
    [MELIO_STATUS_CODES.ERR_VENDOR_NAME_CONFLICT, MELIO_STATUS_CODES.ERR_VENDOR_RENAME_CONFLICT_EXTERNAL_ID].indexOf(
      code
    ) > -1
  );
}

export function escapeXMLString(str: string | null | undefined) {
  return (
    str &&
    str
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&apos;')
  );
}
